<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div>
                    <div class="lockscreen-logo">
                        <!-- <a href="/ujian"></a> -->
                        <img src="@/assets/img/logo-tosca.png" alt="logo" width="250">
                    </div>
                    <!-- User name -->
                    <div class="text-center" style="padding: 0px;"><img class="img-bordered-sm" :src="info.img_url" alt="user image" style="width:60%; border-radius:20px;"></div>
                    <br>
                    <br>
                    <br>
                    <div class="text-center"><h5></h5></div>
                    <br>
                    <br>
                    <div v-if="true">
                        <div v-for="item in pembahasan" :key="item" style="padding-left: 3%;padding-right: 3%;">
                            <div class="col-md-12">
                                <div class="card card-primary collapsed-card">
                                  <div class="card-header">
                                    <h3 class="card-title">Soal Ke {{ item.nomor_soal }}</h3>

                                    <div class="card-tools">
                                      <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus"></i>
                                      </button>
                                    </div>
                                    <!-- /.card-tools -->
                                  </div>
                                  <!-- /.card-header -->
                                  <div class="card-body">
                                    <!-- <div class="col-md-3" style="width: 10px; display: block;"> -->
                                    <div class="text-left mb-2" style="width: 98%; display: block;" v-html="item.soal"></div>
                                    <!-- </div> -->
                                    <div class="text-center"><h2 style="font-size: 1.2rem;">Kunci Jawaban</h2></div>
                                    <h5 class="text-center mb-3 text-bold" v-html="item.kunci_jawaban"></h5>
                                    <div class="text-center"><h2 style="font-size: 1.2rem;">Pembahasan</h2></div>
                                    <div class="text-center" v-html="item.pembahasan"></div>
                                  </div>
                                  <!-- /.card-body -->
                                </div>
                                <!-- /.card -->
                              </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <router-link :to="{path:`/ujian-mapel-list/`+this.summary.mapel_id+`/`+this.summary.type}" class="btn btn-outline btn-lg btn-secondary mr-3"><i class="fas fa-arrow-left"></i> Kembali ke Daftar Soal</router-link>
                    </div>  
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch, auth } from "@/libs/hxcore";
    export default {
    name: 'SoalFinish',
    data() {
        return {
            kode: '',
            loading:false,
            info: {
                display_name: 'No Name'
            },
            summary: {},
            pembahasan: [],
            hasil: [],
            hasilAll: [],
        };
    },
    created: function () {
        let id = this.$route.params.id;
        this.kode = id;
        this.summary.mapel_id = this.$route.params.mapel_id;
        this.summary.type = this.$route.params.type;
        var info = auth.user();
        this.loading = true;
        if(info)this.info = info;

        this.loadPembahasan();
    },
    methods: {
        finish: function()
        {
            this.loading = true;
            var formData = {
                'kode' : this.kode,
                'hasil_id' : this.summary.id
            };
            var data = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&')
            authFetch('/siswa/test/finish', {
                method: 'POST',
                body: data
            })
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(json => {
              this.loading = false;

              if (json.success) {
                this.summary.correct = json.data;
                if (this.summary.tampilkan_pembahasan == '0') {
                this.$router.push("/ujian-mapel-list/"+this.summary.mapel);
                } else {
                this.loadPembahasan();
                // this.$router.push("/ujian-mapel-list/"+this.summary.mapel);
                }
              }
              // this.loadPembahasan(this.summary.id)
              this.summary.finished_on = true
            });
        },
        loadPembahasan() {
            authFetch("/tentor/ujian/pembahasan?ujian_code=" + this.kode).then((res) => {
                res.json().then((json) => {
                    this.loading = false;
                    if(json.success)
                    {
                        this.pembahasan = json.data;
                    }
                });
            });
        }
    },
}
</script>
<style type="text/css" scoped>
    .img-bordered-sm {
        width: 100%;
        padding: 25px;
        border: none;
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 6%);
    }

    .box-score {
        width: 50%; 
        background-color: green; 
        border-radius: 10px; 
        margin: auto; 
        color: white; 
        font-weight: 600;
        padding: 8px;
        background: #02AAB0;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #00CDAC, #02AAB0);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #00CDAC, #02AAB0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    .box-score-full { 
        background-color: green; 
        border-radius: 10px; 
        margin: auto; 
        color: white; 
        font-weight: 600;
        padding: 8px;
        background: #02AAB0;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #00CDAC, #02AAB0);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #00CDAC, #02AAB0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
</style>
<style>
    img{
        max-width: 100%;
        /* aspect-ratio: auto 4 / 3; */
    }
</style>